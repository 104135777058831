import {Component, Input, OnInit, inject, OnDestroy} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {NgForm} from '@angular/forms';
import TwoRaffleHelpers from '../../helpers/helpers';
import {catchError, filter, finalize, map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {
  CoriunderAppKeys,
  CustomTheme,
  DbStoreModel,
  StockManagePolicy,
  StoreCredential,
  CartClearPolicy,
  StoreType,
  BroadcastResolution,
  StoreAllowedDomain,
} from '../../../../../shared/db-models/store';
import {StoresService} from '../../services/stores.service';
import {environment} from 'src/environments/environment';
import {DbCurrencyModel, PaymentSuppliers} from '../../../../../shared/db-models/payments';
import {sanitizeUrl} from '../../../../../shared/lib/storeHelpers';
import {sanitizeStoreInternalURL} from '../../../../../shared/utilities/url-helpers';
import {BehaviorSubject, combineLatest, forkJoin, Observable, of, Subject} from 'rxjs';
import {Timestamp} from '@angular/fire/firestore';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {CronExpressionEditModalComponent} from '../cron-expression-edit-modal/cron-expression-edit-modal.component';
import {UsersService} from 'src/app/services/users.service';
import {v4} from 'uuid';

const DEFAULT_SESSION_START_TIME_FRAME_HOURS = 24;
const DEFAULT_BROADCAST_RESOLUTION = '720p30fps';

@Component({
  selector: 'app-edit-territory-modal',
  templateUrl: './edit-store-modal.component.html',
  styleUrl: './edit-store-modal.component.scss',
})
export class EditStoreModalComponent implements OnInit, OnDestroy {
  httpClient = inject(HttpClient);
  isLoading = true;
  isSubmitting: boolean;
  destroyed$ = new Subject<void>();

  public errorMessage = '';
  public PaymentSuppliers = PaymentSuppliers;
  private defaultHostImageFile: File | undefined;
  private defaultBannerImageFile: File | undefined;
  public storeTypesOptions = Object.values(StoreType);

  currencies: DbCurrencyModel[] = [];
  storeHasLinkedIntegration: boolean;
  cartClearPolicyList: Record<CartClearPolicy, string> = {
    'by-checkout': 'By checkout',
    'by-payment': 'By payment',
  };

  private _cartClearPolicy: CartClearPolicy;
  managementListPolicy: Record<StockManagePolicy, string> = {
    'by-checkout': 'By checkout',
    'by-add-to-cart': 'By add to cart',
    'no-stock-management': 'No stock management',
  };

  broadcastResolutionSelect: Record<BroadcastResolution, string> = {
    '1080p30fps': '1920x1080 , 30 fps , 5000 kbps',
    '1080p60fps': '1920x1080, 60 fps, 6500 kbps',
    '720p30fps': '1280x720, 30 fps , 3420 kbps',
    '720p60fps': '1280x720, 60 fps, 4500 kbps',
  };

  private _currency: DbCurrencyModel;
  private _stockManagementPolicy: StockManagePolicy;
  private _broadcastResolution: BroadcastResolution;
  private _pictureInPicture: boolean;
  private _mirrorHost: boolean;
  private _embedPostSession: boolean;
  private _embedPreSessionTimeFrame: string;
  private _enableShoppableVideo: boolean;

  get currency() {
    return this._currency;
  }
  set currency(currency: DbCurrencyModel) {
    this._currency = currency;
    this.store.currency = currency.isoCode;
    this.store.currencyId = currency.id;
  }

  get cartClearPolicy() {
    return this._cartClearPolicy;
  }

  set cartClearPolicy(cartClearPolicy: CartClearPolicy) {
    this._cartClearPolicy = cartClearPolicy;
    this.store.cartClearPolicy = cartClearPolicy;
  }

  get mirrorHost() {
    return this._mirrorHost;
  }
  set mirrorHost(mirrorHost: boolean) {
    this._mirrorHost = mirrorHost;
    this.store.mirrorHost = mirrorHost;
  }
  get embedPostSession() {
    return this._embedPostSession;
  }
  set embedPostSession(embedPostSession: boolean) {
    this._embedPostSession = embedPostSession;
    if (!this.store.storeConfig) {
      this.store.storeConfig = {};
    }
    if (!this.store.storeConfig.embed) {
      this.store.storeConfig.embed = {};
    }
    this.store.storeConfig = {
      ...this.store.storeConfig,
      embed: {...this.store.storeConfig?.embed, embedPostSession},
    };
  }
  get embedPreSessionTimeFrame() {
    return this._embedPreSessionTimeFrame;
  }
  set embedPreSessionTimeFrame(embedPreSessionTimeFrame: string) {
    if (!embedPreSessionTimeFrame) {
      return;
    }
    this._embedPreSessionTimeFrame = embedPreSessionTimeFrame;
    if (!this.store.storeConfig) {
      this.store.storeConfig = {};
    }
    if (!this.store.storeConfig.embed) {
      this.store.storeConfig.embed = {};
    }
    this.store.storeConfig = {
      ...this.store.storeConfig,
      embed: {
        ...this.store.storeConfig?.embed,
        hoursBeforeSessionToEmbed: parseInt(
          embedPreSessionTimeFrame ?? DEFAULT_SESSION_START_TIME_FRAME_HOURS
        ),
      },
    };
  }
  get enableShoppableVideo() {
    return this._enableShoppableVideo;
  }
  set enableShoppableVideo(enableShoppableVideo: boolean) {
    this._enableShoppableVideo = enableShoppableVideo;
    if (!this.store.featureFlags) {
      this.store.featureFlags = {};
    }

    this.store.featureFlags = {
      ...this.store.featureFlags,
      enableShoppableVideo,
    };
  }
  get pictureInPicture() {
    return this._pictureInPicture;
  }
  set pictureInPicture(pictureInPicture: boolean) {
    this._pictureInPicture = pictureInPicture;
    this.store.pictureInPicture = pictureInPicture;
  }

  get stockManagementPolicy() {
    return this._stockManagementPolicy;
  }
  set stockManagementPolicy(stockManagementPolicy: StockManagePolicy) {
    this._stockManagementPolicy = stockManagementPolicy;
    this.store.stockManagementPolicy = stockManagementPolicy;
  }

  get broadcastResolution() {
    return this._broadcastResolution;
  }

  set broadcastResolution(broadcastResolution: BroadcastResolution) {
    this._broadcastResolution = broadcastResolution;
    this.store.broadcastResolution = broadcastResolution;
  }

  get showStoreTokenField() {
    return environment.client.clientType === 't1';
  }

  public get token() {
    return this.t1Credentials$.value?.token;
  }
  public set token(value) {
    const prev = this.t1Credentials$.value;
    this.t1Credentials$.next({
      ...prev,
      token: value ?? '',
    });
  }
  public get externalUrl() {
    return this.t1Credentials$.value?.externalUrl || this.store.externalUrl;
  }
  public set externalUrl(value) {
    const prev = this.t1Credentials$.value;
    if (value) {
      const brandStoreUrlDomain = sanitizeUrl(value, {removePath: true}) as string;
      if (brandStoreUrlDomain) {
        this.store.externalUrl = brandStoreUrlDomain;
      }
    }

    this.t1Credentials$.next({
      ...prev,
      externalUrl: value ?? '',
    });
  }
  public get internalUrl() {
    return this.store.url;
  }
  public set internalUrl(value: string) {
    this.store.url = sanitizeStoreInternalURL(value);
  }
  public t1Credentials$ = new BehaviorSubject<StoreCredential>({externalUrl: '', token: ''});
  public isT1CredentialsAreValid$ = this.t1Credentials$.pipe(
    filter((Credentials) => !!Credentials.externalUrl && !!Credentials.token),
    switchMap((cred) => this.validateT1Credentials(cred))
  );

  @Input() storeId: string = '';

  store = new DbStoreModel();
  updateCoriunder = false;
  coriunder: CoriunderAppKeys = {
    personalhashkey: '',
    merchantnumber: '',
  };

  get haveTheme() {
    return !!this.store.theme;
  }
  set haveTheme(value: boolean) {
    if (value) {
      this.store.theme = this.store.theme ?? ({} as DbStoreModel['theme']);
    } else {
      this.store.theme = {} as DbStoreModel['theme'];
    }
  }
  get theme() {
    return this.store.theme ?? ({} as CustomTheme);
  }
  set theme(theme: CustomTheme) {
    this.store.theme = theme;
  }

  constructor(
    public modal: NgbActiveModal,
    private storesService: StoresService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private usersService: UsersService
  ) {}

  public readonly HOST_FROM_URL_REGEX =
    /^(([^@:\/\s]+):\/?)?\/?(([^@:\/\s]+)(:([^@:\/\s]+))?@)?([^@:\/\s]+)(:(\d+))?(((\/\w+)*\/)([\w\-\.]+[^#?\s]*)?(.*)?(#[\w\-]+)?)?$/;

  ngOnInit() {
    if (!this.storeId) {
      this.isLoading = false;
      this.store.id = this.storesService.getIdForNewStore();
      this.storeId = this.store.id;
      this.cartClearPolicy = 'by-checkout';
      this.stockManagementPolicy = 'by-checkout';
      this.broadcastResolution = DEFAULT_BROADCAST_RESOLUTION;
      this.pictureInPicture = false;
      this.mirrorHost = true;
      this.enableShoppableVideo = false;
      this.initDefaultPic();
      this.loadCurrencies()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((currencies: DbCurrencyModel[]) => (this.currencies = currencies));
      return;
    }

    this.loadStoreAndCurrencies();
    this.storesService
      .getStoreReadOnlyDoc<StoreCredential>(this.storeId, environment.client.clientType)
      .subscribe((storeCredentials) => {
        if (storeCredentials) this.t1Credentials$.next(storeCredentials);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private loadStoreAndCurrencies(): void {
    this.checkIfStoreHasLinkedIntegration().then((val) => {
      this.storeHasLinkedIntegration = val;
    });
    forkJoin({
      store: this.storesService.getStoreById(this.storeId),
      currencies: this.loadCurrencies(),
      allowedDomains: this.storesService.getAllowedDomains(this.storeId),
    })
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (values) => {
          this.currencies = values.currencies;
          const store = values.store;
          if (!store.exists) {
            this.toastr.error("Can't load store.");
            this.modal.dismiss();
          } else {
            Object.assign(this.store, store.data(), {allowedDomains: values.allowedDomains});
            this.store.id = this.storeId;
            const currency = this.currencies.find((cur: DbCurrencyModel) => {
              return cur.id === this.store.currencyId;
            });
            if (currency) {
              this.currency = currency;
            }
            if (this.store.storeType === undefined) {
              this.store.storeType = StoreType.LIVE;
            }
            this.cartClearPolicy = this.store.cartClearPolicy ?? 'by-checkout';

            this.stockManagementPolicy = this.store.stockManagementPolicy ?? 'by-checkout';
            this.broadcastResolution =
              this.store.broadcastResolution ?? DEFAULT_BROADCAST_RESOLUTION;
            this.pictureInPicture = this.store.pictureInPicture ?? false;
            this.mirrorHost = this.store.mirrorHost ?? true;
            this.embedPostSession = this.store.storeConfig?.embed?.embedPostSession ?? false;
            this.embedPreSessionTimeFrame = String(
              this.store.storeConfig?.embed?.hoursBeforeSessionToEmbed ?? ''
            );
            this.enableShoppableVideo = this.store.featureFlags?.enableShoppableVideo ?? false;

            this.isLoading = false;
          }
        },
        error: () => {
          // TODO: Show Error
          this.modal.dismiss();
        },
      });
  }

  private loadCurrencies(): Observable<DbCurrencyModel[]> {
    return this.storesService
      .getAllCurrencies()
      .pipe(map((res) => res.docs.map((doc) => doc.data())));
  }

  private checkIfStoreHasLinkedIntegration(): Promise<boolean> {
    return this.storesService.checkIfStoreHasLinkedIntegration(this.storeId);
  }

  private uploadFileOnSave() {
    const fileUploads: Record<string, Observable<string>> = {default: of('ok')};

    const storeId = this.store.id;

    if (this.defaultHostImageFile) {
      fileUploads['logoUrl'] = this.storesService
        .uploadStoreHostImageFile(storeId, this.defaultHostImageFile)
        .pipe(tap(() => console.debug('uploaded logo image file')));
    }

    if (this.defaultBannerImageFile) {
      fileUploads['bannerUrl'] = this.storesService
        .uploadStoreBannerImageFile(storeId, this.defaultBannerImageFile)
        .pipe(tap(() => console.debug('uploaded banner image file')));
    }

    return combineLatest(fileUploads).pipe(
      tap((response: {[x: string]: string | undefined}) => {
        console.debug('uploading session files finished successfully');
        if (this.defaultHostImageFile && response['logoUrl']) {
          this.store.logoUrl = response['logoUrl'];
        }

        if (this.defaultBannerImageFile && response['bannerUrl'])
          this.store.bannerUrl = response['bannerUrl'];
      }),
      catchError((error) => {
        console.error('Error combining file uploads:', error);
        throw error;
      })
    );
  }

  public async save(f: NgForm) {
    if (f.invalid) {
      TwoRaffleHelpers.markFormInvalidAndFocus(f);
    } else {
      const duplicatesByUrl = await this.storesService.getDuplicatesStoresByProp('url', this.store);
      if (duplicatesByUrl.length > 0) {
        const urlControl = f.controls['url'];
        urlControl.setErrors({duplicate: true});
        urlControl.markAsUntouched();
        TwoRaffleHelpers.markFormInvalidAndFocus(f);
        return;
      }
      this.isSubmitting = true;
      f.form.disable();
      this.uploadFileOnSave().subscribe({
        next: () => {
          console.debug('All files uploaded successfully');
          this.storesService
            .updateStore(Object.assign({}, this.store))
            .pipe(
              finalize(() => {
                this.isSubmitting = false;
                f.form.enable();
              })
            )
            .subscribe(
              () => {
                this.toastr.success('Action was completed successfully');
                this.modal.close();
              },
              (err) => {
                this.toastr.error('Failed to save store. ' + err.message);
              }
            );
        },
        error: (error) => {
          console.error('Error uploading files:', error);
          this.isSubmitting = false;
          f.form.enable();
          // Handle error as needed
        },
      });

      this.storesService.updateAllowedDomains(this.storeId, this.store.allowedDomains).subscribe();

      if (this.updateCoriunder)
        this.storesService
          .setStoreWriteOnlyDoc(this.storeId, 'coriunder', this.coriunder)
          .subscribe();
      const t1Credentials = this.t1Credentials$.getValue();
      if (
        environment.client.clientType === 't1' &&
        typeof t1Credentials?.externalUrl === 'string' &&
        this.HOST_FROM_URL_REGEX.test(t1Credentials?.externalUrl)
      ) {
        this.t1Credentials$.next({
          externalUrl:
            t1Credentials.externalUrl.match(this.HOST_FROM_URL_REGEX)?.[7] ??
            t1Credentials.externalUrl,
          token: t1Credentials.token,
        });
        this.storesService
          .setStoreWriteOnlyDoc(
            this.storeId,
            environment.client.clientType,
            this.t1Credentials$.value
          )
          .subscribe();
      }
    }
  }

  public delete(): void {}
  validateT1Credentials(cred: StoreCredential | null) {
    if (!cred) return of(false);
    const url = cred.externalUrl.match(this.HOST_FROM_URL_REGEX)?.[7];
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        user: `${cred.token}`,
        timestamp: String(Date.now()),
      }),
    };
    return this.httpClient
      .get<{
        'type': number;
        'data': {
          'message': string;
        };
      }>('https://' + url + '/api/products', httpOptions)
      .pipe(
        map((res) => {
          this.errorMessage = res?.data?.message ?? '';
          return res.type === 1;
        }),
        catchError((e) => {
          if (e instanceof HttpErrorResponse) {
            this.errorMessage = e.error?.data?.message ?? e.message ?? e.statusText ?? '';
          }
          return of(false);
        })
      );
  }
  private async initDefaultPic() {
    this.storesService
      .getFileFromAssets(
        'assets/images/defaultStoreBanner.jpg',
        'defaultStoreBanner.jpg',
        'image/jpg'
      )
      .subscribe((file) => {
        this.defaultBannerImageFile = file;
      });

    this.storesService
      .getFileFromAssets('assets/images/defaultStoreLogo.png', 'defaultStoreLogo.png', 'image/png')
      .subscribe((file) => {
        this.defaultHostImageFile = file;
      });
  }

  openCronExpressionEditModal() {
    const modal = this.modalService.open(CronExpressionEditModalComponent, {
      size: 'xl',
      backdrop: 'static',
      windowClass: 'dark-modal ltr',
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
    });
    modal.componentInstance.storeCronExpression = this.store.cronScheduledSync;
    modal.result.then((value) => {
      if (value) {
        this.store.cronScheduledSync = value;
      }
    }, null);
  }

  formatDate(timestamp: {seconds: number; nanoseconds: number}): string {
    const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
    return date.toLocaleString(); // Adjust format as needed
  }

  addNewAllowedDomain(): void {
    const newItem: StoreAllowedDomain = {
      addressPattern: 'https://',
      createdAt: new Timestamp(Date.now() / 1000, 0),
      createdBy: this.usersService.getConnectedUserSync()?.uid || 'unknown-user',
      updatedBy: this.usersService.getConnectedUserSync()?.uid || 'unknown-user',
      updatedAt: new Timestamp(Date.now() / 1000, 0),
      isEnabled: false,
      id: v4(),
    };

    this.store.allowedDomains = [...(this.store.allowedDomains || []), newItem];
  }

  trackById(_: number, item: any): string {
    return item.id;
  }

  removeItem(index: number): void {
    this.store.allowedDomains = this.store.allowedDomains?.filter((_, i) => i !== index);
  }
}
